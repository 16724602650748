@import 'intl-tel-input/build/css/intlTelInput.css';
@import 'primeicons/primeicons.css';

p {
  max-width: 100%;
  line-height: 1.5;
}

hr {
  margin: 0.5rem 0 1rem 0;
  color: inherit;
  border: 0;
  border-top: 2px solid;
  opacity: 1;
}

hr.black-line {
  color: black;
}

.grid {
  margin: 0;
}

.p-panel .p-panel-header,
.p-drawer {
  background: #f1f1f1;
  color: #112e51;
}

.p-drawer-header {
  font-family: karla, sans-serif;
  font-weight: bold;
  color: #112e51;
  font-size: 16px;
}

.p-panel-content a {
  color: black;
  text-decoration: none;
  border-bottom: 1px black dotted;
}

.p-drawer-bottom {
  height: fit-content;
}

li {
  list-style-type: none;
}

.ng-submitted .p-inputtext.ng-invalid,
.p-inputtext.ng-invalid.ng-touched,
.ng-submitted p-select.ng-invalid div.p-select,
p-select.ng-invalid.ng-touched div.p-select,
.ng-submitted p-inputnumber.ng-invalid input.p-inputtext,
p-inputnumber.ng-invalid.ng-touched input.p-inputtext,
.ng-submitted p-calendar.ng-dirty.ng-invalid input.p-inputtext,
p-calendar.ng-dirty.ng-invalid.ng-touched input.p-inputtext,
.ng-submitted p-autocomplete.ng-invalid input.p-inputtext,
p-autocomplete.ng-invalid.ng-touched input.p-inputtext {
  border-color: #dc3545;
}

.contact-dialog-not-submitted .p-inputtext.ng-invalid,
.contact-dialog-not-submitted p-select.ng-invalid div.p-select {
  border: 2px solid #aeb0b5;
}

label.disabled {
  color: rgba(118, 118, 118, 0.3);
}

.label-note {
  color: grey;
  font-family: karla, sans-serif;
}

.bold {
  font-weight: bolder;
}

.normal {
  font-weight: normal;
}

.readonly {
  background-color: #f1f1f1 !important;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.memo-checkbox-note {
  padding-left: 28px;
  padding-bottom: 10px;
  font-style: italic;
}

.contact-confirm-dialog li {
  list-style-type: disc;
  margin-left: 2rem;
}

.collapse {
  border-collapse: collapse;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.gray {
  background-color: #f1f1f1;
}

.dark-gray {
  background-color: #aeb0b5;
}

.dark-gray-text {
  color: #aeb0b5;
}

.font-karla {
  font-family:
    karla,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
}

.font-karla-bold {
  font-family:
    karla-bold,
    karla,
    Segoe UI,
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol;
}

.fec-background-gradient {
  background-image: linear-gradient(to bottom right, #1a4f82, #112e51);
}

.radio-button-spacer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.button-full-width {
  width: 100%;
}

.image-max-width {
  max-width: 100%;
}

.no-padding {
  padding: 0;
}

.no-vert-margins {
  margin-top: 0;
  margin-bottom: 0;
}

.no-wrap {
  white-space: nowrap;
}

.word-break {
  word-break: break-word;
}

.super-header {
  font-size: 48px;
}

.form-type-dialog {
  width: 50%;
}

.form-type-dialog p-select {
  display: flex;
}

.title1 {
  font-family: ghandi-bold, serif;
  font-size: 32px;
}

.add-button {
  color: #212121;
  background-color: var(--orange);
  border-color: var(--orange);
  border: 0;
  font-weight: 600;
}

.add-button > .p-button-label {
  font-weight: 600;
}

.add-button:hover {
  background: #f78d5b !important;
  border-color: #f78d5b !important;
  color: #212121 !important;
  font-weight: 600;
}

// PRIMENG PANELMENU STYLE OVERRIDE

.menu-item-disabled {
  opacity: 0.3;
}

.p-panelmenu-icon {
  display: none;
}

.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem 1.25rem;
  color: white;
  background-color: transparent;
  font-weight: 600;
  border: 0;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background-color: transparent;
  border-color: #dee2e6;
  color: white;
}

.p-panelmenu-header.p-panelmenu-header-active {
  margin-bottom: 0;
  border-left: 5px solid white;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background-color: transparent;
  color: white;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  background-color: transparent;
  color: white;
}

.p-panelmenu .p-panelmenu-content {
  background-color: transparent;
  color: white;
  margin-bottom: 0;
  border: 0;
  padding-left: 1em;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: white;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: white;
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background-color: transparent;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: white;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
  border-top: 1px solid white;
}

.p-panelmenu .p-panelmenu-panel:first-of-type {
  border-top: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a,
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-select {
  height: 48px;
}

.pi-trash {
  cursor: pointer;
}

.pi-trash.disabled {
  opacity: 30%;
}

.p-tooltip .p-tooltip-text {
  background: white;
  color: black;
  border: 1px solid black;
}

.action-options {
  &:before {
    content: none !important;
  }

  &:after {
    content: none !important;
  }

  padding: 0;
  margin-top: 0 !important;
}

.action-options .p-popover-content {
  padding: 0.5em;
}

.action-options .p-popover-content .p-button {
  width: 100%;
  text-align: inherit;
}

.p-button-primary.p-button {
  border-width: 2px;
  padding: 8px 20px;
  font-size: 14px;
}

.p-button-primary.p-button:enabled:hover {
  background-color: #164f85;
  border-color: #164f85;
}

.p-button-secondary.p-button {
  background-color: #aeb0b5;
  border-color: #aeb0b5;
  border-width: 2px;
  padding: 8px 20px;
  font-size: 14px;
  color: #212121;
}

.p-button-secondary.p-button:enabled:hover {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  color: #212121;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

table {
  caption-side: top;
  border-collapse: collapse;
}

.card {
  border: 0;
}

.pi-ellipsis-v {
  font-weight: 900;
}

.optional-text {
  color: #aeb0b5;
}

.top-border-footer {
  display: flex;
  width: 100%;
  border-top: 1px solid var(--surface-hover);
  padding: 1.25rem 1.5rem 0.5rem 1.5rem;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
  white-space: nowrap;
  height: 53px;
}

.circle-icon-tooltip {
  font-size: 1.5rem;
  margin-left: 0.5rem;
}
