@font-face {
  font-family: gandhi-bold;
  src: url(/assets/fonts/gandhi-serif-bold.otf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: gandhi-bold-italic;
  src: url(/assets/fonts/gandhi-serif-bold-italic.otf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: gandhi-italic;
  src: url(/assets/fonts/gandhi-serif-italic.otf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: gandhi-regular;
  src: url(/assets/fonts/gandhi-serif-regular.otf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: karla-bold;
  src: url(/assets/fonts/karla-bold.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: karla;
  src: url(/assets/fonts/karla-regular.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fec_currency_mono;
  src:
    url(/assets/fonts/fec-currencymono-bold.woff2) format('woff2'),
    url(/assets/fonts/fec-currencymono-bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: fec_currency_mono;
  src:
    url(/assets/fonts/fec-currencymono-bolditalic.woff2) format('woff2'),
    url(/assets/fonts/fec-currencymono-bolditalic.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
